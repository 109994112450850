<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import NewsModel from '@/modules/news/news-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import moment from 'moment';
import DateRangePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { getIdFromUrl } from 'vue-youtube';
import SelectWithCheckBox from '@/shared/components/SelectorWithCheckBox.vue';

const { fields } = NewsModel;
const store = {
  state: {
    options: [],
    optionsDep: [],
    optionsGroup: [],
    idYoutube: null,
    datePublish: new Date(),
  },
};
export default {
  name: 'AddNew',
  components: {
    BaseButton,
    DateRangePicker,
    SelectWithCheckBox,
  },
  props: {
    newId: {
      type: Number,
      required: true,
      default: 0,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      dropped: 0,
      error: '',
      store,
      files: [],
      Img: [],
      Video: [],
      OldVideo: [],
      OldImg: [],
      model: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    };
  },
  methods: {
    previewVideo(event) {
      this.videos = event.currentTarget.files;
      this.error = '';
      this.$emit('changed', this.videos);
      const readers = [];
      if (!this.videos.length) return;
      for (let i = 0; i < this.videos.length; i += 1) {
        readers.push(this.readAsDataURL(this.videos[i]));
      }
      Promise.all(readers).then((values) => {
        this.Video = values;
        this.OldVideo = [];
      });
    },
    dragOver() {
      this.dropped = 2;
    },
    drop(e) {
      let status = true;
      const files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith('video') === false) status = false;
        });
        if (status === true) {
          this.files = files;
          e.currentTarget.files = e.dataTransfer.files;
          this.previewVideo(e);
        } else {
          this.error = this.$props.fileError ? this.$props.fileError : 'Unsupported file type';
        }
      }
      this.dropped = 0;
    },
    ListBranches(parentId, parentType) {
      const listDepPath = '/WebListBranches.php';
      const data = new FormData();
      data.append('parent_id', parentId);
      data.append('parent_type', parentType);
      data.append('levels', 2);
      fetch(server + listDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.branches && json.branches.length) {
          store.state.options = json.branches;
          json.branches.forEach((branch) => {
            if (branch.type === 'department') {
              store.state.optionsDep.push({
                value: branch.id,
                text: branch.name,
              });
              branch.branches.forEach((group) => {
                if (group.type === 'group') {
                  store.state.optionsGroup.push({
                    value: group.id,
                    text: group.name,
                  });
                }
              });
            }
            if (branch.type === 'group') {
              store.state.optionsGroup.push({
                value: branch.id,
                text: branch.name,
              });
            }
          });

          if (parentType === '') {
            this.model.departament = store.state.optionsDep;
          }
          this.model.group = store.state.optionsGroup;
        }
      });
    },
    getNew(idNew) {
      const getNewPath = '/WebGetAnnouncementDetails.php';

      const data = new FormData();
      data.append('id', idNew);
      fetch(server + getNewPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            let temp = [];
            const temp2 = [];
            let i = 0;
            let j = 0;
            store.state.options.forEach((opt) => {
              if (opt.type === 'department') {
                temp2[j] = {
                  value: opt.id,
                  text: opt.name,
                };
                j += 1;
              }
              json.departments.forEach((dep) => {
                if (dep.id === opt.id) {
                  opt.branches.forEach((group) => {
                    if (group.type === 'group') {
                      temp[i] = {
                        value: group.id,
                        text: group.name,
                      };
                      i += 1;
                    }
                  });
                }
              });
            });
            store.state.optionsGroup = JSON.parse(JSON.stringify(temp));
            store.state.optionsDep = JSON.parse(JSON.stringify(temp2));
            this.model.title = json.title;
            this.model.description = json.description;
            this.OldImg = json.image ? [json.image] : [];
            store.state.datePublish.setTime(
              moment(json.publication_datetime).toDate().getTime(),
            );
            this.model.datePublish = store.state.datePublish;
            this.model.link = json.youtube_link !== null ? json.youtube_link : '';
            store.state.idYoutube = json.youtube_link !== null
              ? getIdFromUrl(json.youtube_link) : null;

            this.OldVideo = json.video ? [json.video] : [];
            temp = [];
            json.departments.forEach((dep) => {
              temp.push({
                value: dep.id,
                text: dep.name,
              });
            });
            this.model.departament = [...temp];

            temp = [];
            json.groups.forEach((dep) => {
              temp.push({
                value: dep.id,
                text: dep.name,
              });
            });
            this.model.group = [...temp];
          }
        });
    },
    addNew() {
      this.openAddNew();
      let addNewPath = '';
      const data = new FormData();
      if (parseInt(this.newId, 10) !== 0) {
        addNewPath = '/WebEditAnnouncement.php';
        data.append('id', this.newId);
      } else {
        addNewPath = '/WebAddAnnouncement.php';
      }

      if (this.model.departament) {
        data.append('department_ids', this.model.departament.map((dep) => dep.value));
      }
      if (this.model.group) {
        data.append('group_ids', this.model.group.map((dep) => dep.value));
      }
      if (this.model.title !== undefined) {
        data.append('title', this.model.title);
      }
      if (this.model.description !== undefined) {
        data.append('description', this.model.description);
      }
      if (this.model.link !== undefined) {
        data.append('youtube_link', this.model.link);
      }

      if (this.Img[0]) {
        data.append('image', this.Img[0]);
      }

      data.append('video', this.Video);
      if (this.model.datePublish !== null && this.model.datePublish !== undefined) {
        data.append('publication_datetime', moment(this.model.datePublish).format('YYYY-MM-DD HH:mm:ss'));
      }
      fetch(server + addNewPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            this.callback();
          }
        });
    },
    openAddNew() {
      const addNewWindow = document.querySelector('.add-item');
      addNewWindow.classList.toggle('show');
      if (addNewWindow.classList.value.includes('hide')) {
        addNewWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addNewWindow.classList.toggle('hide');
        }, 300);
      }
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.Img = values;
        this.OldImg = [];
      });
    },
    deleteNew(id) {
      this.openAddNew();
      const deleteUserPath = '/WebRemoveAnnouncement.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.callback();
        }
      });
    },
    updateDep(val) {
      this.model.departament = val;
      store.state.optionsGroup = [];
      val.forEach((dep) => {
        this.ListBranches(dep.value, 'department');
      });
    },
    updateGroup(val) {
      this.model.group = val;
    },
  },
  beforeMount() {
    this.model.group = [];
    store.state.optionsDep = [];
    this.Video = [];
    this.OldVideo = [];
    store.state.optionsGroup = [];

    if (this.newId !== 0) {
      this.getNew(this.newId);
    } else {
      this.ListBranches(0, '');
      this.model.title = '';
      this.OldImg = [];
    }
  },
};
</script>
<template>
  <div class="add-item">
    <div class="add-item-left" v-on:click="openAddNew">
    </div>
    <div class="add-item-window">
      <form class="form" v-on:submit.prevent="addNew(1)"
            id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.model.name">
          <div class="menu">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddNew"/>
            <div class="title">
              {{ this.newId !== 0 ? this.model.title : $t('NEWS.ADD_NEWS') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit">
              <unicon fill="white" height="16" width="16" name="save"/>
              {{ $t('GENERAL.SAVE') }}
            </BaseButton>
          </div>
          <div class="content">
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.BASIC_INFO') }}
                </div>
              </b-col>
            </b-row>
            <div style="display: inline-flex;width: 100%;padding: 0 24px">
              <div style="margin-right: 24px">
                <div class="upload-photo">
                  <img :src="this.OldImg.length !== 0 ? this.OldImg : this.Img" alt=""
                       v-if="this.Img.length !== 0 || this.OldImg.length !== 0"/>
                  <unicon class="unicon-image" name="image"
                          v-if="this.Img.length === 0 && this.OldImg.length === 0"
                          width="80" height="80" fill="white"/>
                  <div class="upload-photo-over">
                    <input
                      type="file"
                      style="z-index: 1;opacity: 0;position: absolute;bottom: 0;
                      height: 190px;width: 190px;"
                      accept="image/*"
                      ref="uploadInput"
                      @change="previewImgs"
                      multiple
                    />
                    <unicon name="camera" width="40" height="40" fill="white"/>
                  </div>
                </div>
              </div>
              <div style="width: -webkit-fill-available;">
                <div class="custom-input">
                  <label>
                    {{ fields.title.label }}</label>
                  <input type="text"
                         :id="fields.title.name"
                         v-model="model[fields.title.name]"
                         :placeholder="fields.title.label"
                         required
                         maxlength="200"
                  >
                </div>
              </div>
            </div>
            <div class="form-separator">

            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ fields.departament.label }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4" style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col md="6" class="pe-0 image-answer"  v-if="store.state.optionsDep.length > 0 &&
                   ((this.newId === 0 ) || ( this.newId !== 0))">
                <label>
                  {{ fields.departament.label }}</label>
                <SelectWithCheckBox
                  :options="store.state.optionsDep"
                  :value="this.model.departament"
                  :update-val="updateDep"
                  :key="'dep' + this.model.departament.length"
                />
              </b-col>
              <b-col md="6" v-if="store.state.optionsGroup.length > 0" class="pe-0 image-answer"
              >
                <label>
                  {{ fields.group.label }}</label>
                <SelectWithCheckBox
                  :options="store.state.optionsGroup"
                  :value="this.model.group"
                  :key="'gr' + this.model.group.length"
                  :update-val="updateGroup"
                />
              </b-col>
            </b-row>
            <div class="form-separator">
            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ fields.description.label }}
                </div>
              </b-col>
            </b-row>
            <b-row style="margin: 0 12.5px 24px 12.5px!important;">
              <div class="custom-input" style="height: 108px">
                <label>
                  {{ fields.description.label }}</label>
                <textarea type="text"
                          class="form-control-2"
                          :id="fields.description.name"
                          v-model="model[fields.description.name]"
                          :placeholder="fields.description.label"
                          required
                          maxlength="500"
                />
              </div>
            </b-row>

            <div class="form-separator">
            </div>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ fields.link.label }}
                </div>
              </b-col>
            </b-row>
            <b-row style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col sm="12">
                <div class="custom-input">
                  <label>
                    {{ fields.link.label }}</label>
                  <unicon name="link" y="-6" style="top: 36px"
                          width="18" height="18" fill="#828282"/>
                  <input type="text"
                         class="form-control floatingInput"
                         :id="fields.link.name"
                         v-model="model[fields.link.name]"
                         :placeholder="fields.link.label"
                         style="padding-left: 40px;margin-bottom: 16px"
                  >
                </div>
              </b-col>

              <b-col sm="12" :key="this.model.link"
                     v-if="this.model.link === null || this.model.link === ''
                     || this.model.link === undefined">
                <div
                    class="container-upload"
                    @dragover.prevent="dragOver"
                    @drop.prevent="drop($event)"
                >
                  <div class="drop" v-show="dropped == 2"></div>
                  <!-- Error Message -->
                  <div v-show="error" class="error">
                    {{ error }}
                  </div>

                  <!-- To inform user how to upload image -->
                  <div class="beforeUpload">
                    <input
                        type="file"
                        style="z-index: 1"
                        accept="video/*"
                        ref="uploadInput"
                        @change="previewVideo"
                    />
                    <img class="plus-image" src="/media/fi_upload-cloud.svg" alt=""/>

                    <p class="mainMessage d-inline-flex mb-0">
                      {{ $t("GENERAL.DRAG_AND_DROP_FILES") }}
                    </p>
                  </div>
                </div>
              </b-col>

              <b-col sm="12">
                <iframe
                    class="video-cont"
                    :src="`https://www.youtube.com/embed/${store.state.idYoutube}`"
                    v-if="store.state.idYoutube !== null"
                >
                </iframe>
              </b-col>

              <b-col sm="12">
                <video
                  controls
                    class="video-cont"
                    :src="Video"
                    v-if="Video.length !== 0"
                >
                </video>
              </b-col>
              <b-col sm="12" v-for="(video, i) in OldVideo" :key="i">
                <video
                  controls
                    class="video-cont"
                    :src="video"
                >
                </video>
              </b-col>
            </b-row>
            <div class="form-separator" v-if="newId !== 0">
            </div>
            <b-row style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col sm="12">
                <div class="title-2-bold-20 basic-2">
                  {{ $t("NEWS.PUBLISHING_DATE") }}
                </div>
                <div style="position: relative;width: fit-content">
                  <DateRangePicker
                    type="datetime"
                    v-model="model.datePublish"
                    :inline="true"
                    :lang="lang"
                    :format="'YYYY-MM-DD HH:mm:ss'"
                    required
                  >
                  </DateRangePicker>
                  <input class="datePublish"
                         style="position:absolute;bottom: 2px;left: 50%;width: 1px;
                            padding: 2px;border: 0;height: 1px"
                         v-model="model.datePublish" required/>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col" md="12" v-if="newId !== 0">
                <BaseButton
                    :variant="'danger'"
                    :callback="deleteNew"
                    :callback-params="newId"
                    style="width: 100%"
                >

                  <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                  {{ $t('GENERAL.DELETE') }}
                </BaseButton>
              </b-col>
            </b-row>

            <div
              style="display: flex; align-items: center; justify-content: center">
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
iframe.video-cont,video.video-cont {
  width: 100%;
  border-radius: 8px;
}

.datePublish:focus-visible, .datePublish:focus {
  border: none;
  outline: none;
}
.beforeUpload {
  position: relative;
}

.beforeUpload .plus-image{
  height: 32px;
  width: 32px;
  display: inline-flex;
  margin-right: 19px;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload input:hover {
  cursor: pointer;
}
</style>
