<script>
import BaseButton from '@/shared/components/BaseButton.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import moment from 'moment';
import Notify from '@/shared/utils/notify';
import { getIdFromUrl } from 'vue-youtube';
import NewsCard from '@/shared/cards/NewsCard.vue';
import AddNew from '@/shared/components/AddNew.vue';

const store = {
  state: {
    newId: 0,
  },
};

export default {
  components: { BaseButton, NewsCard, AddNew },
  data() {
    return {
      store,
      state: {
        news: [],
      },
    };
  },
  methods: {
    getNews() {
      this.state.news = [];
      const getNewsPath = '/WebListAllAnnouncements.php';

      fetch(server + getNewsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            json.announcements.forEach((item) => {
              this.state.news.push({
                title: item.title,
                description: item.description,
                id: item.id,
                image: item.image,
                parents: item.parents,
                publication_datetime: item.publication_datetime,
                video: item.video,
                youtube_link: item.youtube_link,
                time: moment(item.publication_datetime).fromNow(),
                published: moment(item.publication_datetime).fromNow().includes('in'),
                id_youtube: item.youtube_link !== null ? getIdFromUrl(item.youtube_link) : null,
              });
            });
          }
        });
    },
    openAddNew() {
      const addNewWindow = document.querySelector('.add-item');
      addNewWindow.classList.toggle('show');
      if (addNewWindow.classList.value.includes('hide')) {
        addNewWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addNewWindow.classList.toggle('hide');
        }, 300);
      }
    },
    editNews(news) {
      setTimeout(() => {
        store.state.newId = news.id ? news.id : 0;
      }, 300);
      this.openAddNew();
    },
  },
  beforeMount() {
    this.getNews();
  },
};
</script>
<template lang="html">
  <div>
    <div class="d-inline-flex" style="width: -webkit-fill-available;margin-bottom: 24px">
      <div class="title-0-medium-28 basic-1"
           style="margin-right: 40px;display: flex;align-items: center">
        {{ $t("GENERAL.NEWS") }}
      </div>
      <div class="basic-3 subhead-reg-14" style="margin: 6px 0;display: flex;align-items: center">
        <unicon name="estate" width="16" height="16" fill="#1694D0"></unicon>
        <unicon name="angle-right" width="20" height="20" fill="#828282"/>
        <div class="d-inline">
          {{ $t("GENERAL.NEWS") }}
        </div>
      </div>
      <div v-on:click="editNews(0)"  style="width: -webkit-fill-available;">
        <BaseButton style="float: right">
          <unicon name="plus" width="16" height="16"
                  style="margin-right: 12px;margin-top: -2px" fill="white"/>
          {{ $t('NEWS.ADD_NEWS') }}
        </BaseButton>
      </div>
    </div>
    <b-row style="margin: 0 -12.5px!important;">
      <b-col xl="3" lg="4" md="6" sm="12" v-for="(item,i) in this.state.news" :key="i">
        <NewsCard
          :new-info="item"
          :callback="editNews"
          :callback-params="item"
        >
        </NewsCard>
      </b-col>
    </b-row>
    <div class="add-item hide">
      <AddNew :key="store.state.newId" :new-id="store.state.newId"
              :callback="getNews">
      </AddNew>
    </div>
  </div>

</template>

<style lang="scss">

</style>
