<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'NewsCard',
  props: {
    newInfo: {
      type: Object,
    },
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
    },
  },
  methods: {
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
  },
};
</script>
<template>
  <div class="container-card-news" style="height: calc(100% - 22px)"
       v-on:click="clickCallback()">
    <b-row>
      <b-col class="new-video">
        <img :src="newInfo.image ? newInfo.image: '/media/learn.jpg'"
             v-if="newInfo.id_youtube === null"
             />
        <iframe
            :src="`https://www.youtube.com/embed/${newInfo.id_youtube}`"
            v-if="newInfo.id_youtube !== null"
        >
        </iframe>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="new-info">
        <div class="title-2-bold-20 basic-1"
             style="text-overflow: ellipsis;height: 24px;overflow: hidden">
          {{ newInfo.title }}
        </div>
        <div class="basic-2 subhead-reg-14"
             style="margin-bottom: 7px;height: 67px;overflow: hidden">
          {{ newInfo.description }}
        </div>
        <div v-if="newInfo.publication_datetime" class="time caption-1-reg-13 basic-1">
          <unicon name="calender" fill="#333333"
                 height="16" width="16" style="margin-right: 8px"/>
          {{ newInfo.publication_datetime }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.container-card-news {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-height:375px;
  margin-bottom:24px;
  position: relative;
  cursor: pointer;
}

.container-card-news:hover{
  box-shadow: 0px 49px 34px -50px rgba(18, 43, 177, 0.3), 0px 19px 25px -6px rgba(18, 43, 177, 0.2);
}

.container-card-news .new-video{
  height: 171px;
  width: 100%;
  border-radius: 12px 12px 0 0 ;
  overflow: hidden;
  padding: 0;
}
.container-card-news .new-video img , .container-card-news .new-video iframe {
  width: 100%;
}

.container-card-news .new-info {
  padding: 12px 16px 16px 16px;
  text-overflow: ellipsis;
}

.container-card-news .time {
  background: #F5F6F8;
  border-radius: 8px;
  width: 100%;
  padding: 4px;
}
</style>
