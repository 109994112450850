import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`NEWS.FIELDS.${name.toUpperCase()}`);

const fields = {
  departament: new StringField('departament', label('departament'), {
    required: true,
  }),
  group: new StringField('group', label('group'), {
    required: true,
  }),
  title: new StringField('title', label('title'), {
    required: true,
  }),
  description: new StringField('description', label('description'), {
    required: true,
  }),
  images: new StringField('images', label('images'), {
    required: true,
  }),
  link: new StringField('link', label('link'), {
    required: true,
  }),
  datePublish: new Date(),
};

export default class NewsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
